<div class="container margin-top">
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <div class="row">
          <div class="col-sm-4">
            <button md-raised-button color="primary" (click)="locationAngular.back()">Go Back</button>
          </div>
          <div class="col-sm-4 text-center">
            <h1>{{ afService?.selectedFacility?.name}}</h1>
            <h5>{{afService?.selectedLocation?.name}}</h5>
            <h3>
              {{afService?.selectedLocation?.address}}<br>
              {{afService?.selectedLocation?.address2}}<br>
              {{afService?.selectedLocation?.phone}}<br>
            </h3>
          </div>
          <div class="col-sm-4">
            <img src="{{ afService?.selectedFacility?.logoUrl }}" class="logo" alt="">
          </div>
        </div>

        <div class="row">
          <div class=" button-wrapper">
            <button class="buttons" *ngIf="afService?.selectedFacility?.propertySoftware === 'sitelink'" md-raised-button [mdMenuTriggerFor]="reportsMenu" color="accent">Reports</button>
            <md-menu #reportsMenu="mdMenu">
              <button md-menu-item [routerLink]="['./reports/view']">View Reports</button>
              <button md-menu-item [routerLink]="['./reports/emails']">Set Up Report Emails</button>
            </md-menu>
            <button class="buttons" md-raised-button color="accent" [routerLink]="['./page-copy']">Page Copy</button>
            <button class="buttons" md-raised-button color="accent" [routerLink]="['./email-notifications']">Email Notifications</button>
            <button  *ngIf="afService?.selectedFacility?.propertySoftware == 'none'" class="buttons" md-raised-button color="accent" [routerLink]="['./unit-settings']">Available Units</button>
            <button class="buttons" md-raised-button color="accent" [routerLink]="['./office-hours']">Office Hours</button>
          </div>
        </div>

        <div class="ui-grid ui-grid-responsive ui-fluid margin-top" *ngIf="afService?.selectedLocation">
          <md-card-header>
            <md-card-title><h2>Location Information</h2></md-card-title>
          </md-card-header>          <div class="row sm-padding-top">
            <div class="col-sm-6">
              <label for="name">Name</label>
              <input pInputText id="name" [(ngModel)]="afService.selectedLocation.name" />
            </div>
            <div class="col-sm-6">
              <label for="tagLine">Tag Line</label>
              <input pInputText id="tagLine" [(ngModel)]="afService?.selectedLocation.tagLine" />
            </div>
          </div>
          <div class="row sm-padding-top">
            <div class="col-sm-6">
              <label for="address">Address</label>
              <input pInputText id="address" [(ngModel)]="afService?.selectedLocation.address" />
            </div>
            <div class="col-sm-6">
              <label for="address2">City, State & Zip</label>
              <input pInputText id="address2" [(ngModel)]="afService?.selectedLocation.address2" />
            </div>
          </div>
          <div class="row sm-padding-top">
            <div class="col-sm-6">
              <label for="phone">Phone</label>
              <p-inputMask id="phone" mask="(999) 999-9999" [(ngModel)]="afService?.selectedLocation.phone"></p-inputMask>
            </div>
            <div class="col-sm-6">
              <label for="email">Email</label>
              <input pInputText id="email" [(ngModel)]="afService?.selectedLocation.email" />
            </div>
          </div>
          <div class="row sm-padding-top">
            <div class="col-sm-6">
              <label for="imageUrl">Location Image URL</label>
              <input pInputText id="imageUrl" [(ngModel)]="afService?.selectedLocation.imageUrl" />
              <div *ngIf="afService?.selectedLocation.imageUrl">
                <img class="location-image" [src]="afService?.selectedLocation.imageUrl" alt="">
              </div>
            </div>
          </div>
          <div class="row sm-padding-top">
            <div class="col-sm-12">
              <button class="location-button" md-raised-button color="primary" (click)="updateLocation()">Update Information</button>
            </div>
          </div>
        </div>
      </md-card>
    </div>
  </div>
</div>
