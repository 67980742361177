<div class="container margin-top margin-bottom">
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <div class="row">
          <div class="col-sm-4">
            <button md-raised-button color="primary" (click)="locationAngular.back()">Go Back</button>
          </div>
          <div class="col-sm-4 text-center">
            <h1>{{ afService?.selectedFacility?.name}}</h1>
            <h5>{{afService?.selectedLocation?.name}}</h5>
            <h3>
              {{afService?.selectedLocation?.address}}<br>
              {{afService?.selectedLocation?.address2}}<br>
              {{afService?.selectedLocation?.phone}}<br>
            </h3>
          </div>
          <div class="col-sm-4 hidden-xs">
            <img src="{{ afService?.selectedFacility?.logoUrl }}" class="logo" alt="">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-offset-3">
            <div *ngIf="error" class="error" role="alert">
              <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
              {{error }}
            </div>
          </div>
        </div>


        <div class="row margin-top">
          <div class="col-md-10 col-md-offset-1">
            <new-report-email [facilityKey]="key" [locationKey]="locKey"></new-report-email>
          </div>
        </div>

      </md-card>

    </div>
  </div>
</div>
<!--<div id="spinner" *ngIf="showSpinner">-->
<!--<div class="loading-spinner"></div>-->
<!--</div>-->
