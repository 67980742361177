<div class="row padding-bottom">
  <div class="col-md-12">
    <p-dataTable [value]="emailList | async"
                 selectionMode="single"
                 [(selection)]="selectedEmail"
                 (onRowSelect)="onEmailRowSelect($event)"
                 [responsive]="true">
      <p-header>{{emailName}}</p-header>
      <p-column field="name" header="Name"></p-column>
      <p-column field="email" header="Email"></p-column>
      <p-footer>
        <div class="ui-helper-clearfix" style="width:100%">
          <button type="button" md-raised-button color="primary" style="float:left" (click)="showEmailDialogToAdd()">
            <i class="fa fa-plus" aria-hidden="true"></i> Add
          </button>
        </div>
      </p-footer>
    </p-dataTable>
  </div>
</div>

<p-dialog header="{{emailName}} Details"
          [(visible)]="displayEmailDialog"
          [responsive]="true"
          showEffect="fade"
          [modal]="true"
          [positionTop]="100"
          [dismissableMask]="true"
          [width]="400">
  <div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="email">
    <div class="ui-grid-row sm-padding-top">
      <div class="ui-grid-col-4"><label for="name">Name</label></div>
      <div class="ui-grid-col-8"><input pInputText id="name" [(ngModel)]="email.name" /></div>
    </div>
    <div class="ui-grid-row sm-padding-top">
      <div class="ui-grid-col-4"><label for="email">Email</label></div>
      <div class="ui-grid-col-8"><input pInputText id="email" [(ngModel)]="email.email" /></div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button md-raised-button *ngIf="!newEmail" style="float:left" (click)="deleteEmail()"><i class="fa fa-close" aria-hidden="true"></i> Delete</button>
      <button md-raised-button color="primary" (click)="saveEmail()"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
    </div>
  </p-footer>
</p-dialog>
