<div class="container margin-top">
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <div class="row">
          <div class="col-sm-6 col-md-offset-3">
            <div *ngIf="error" class="error" role="alert">
              <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
              {{error }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <button md-raised-button color="primary" (click)="locationAngular.back()">Go Back</button>
          </div>
          <div class="col-sm-4 text-center">
            <h1>{{ afService?.selectedFacility?.name}}</h1>
            <h5>{{afService?.selectedLocation?.name}}</h5>
            <h3>
              {{ afService?.selectedLocation?.address }}<br>
              {{ afService?.selectedLocation?.address2 }}<br>
              {{ afService?.selectedLocation?.phone }}<br>
            </h3>
          </div>
          <div class="col-sm-4">
            <img src="{{ afService?.selectedFacility?.logoUrl }}" class="logo" alt="">
          </div>
        </div>
        <div class="row margin-top" *ngIf="pageSettings?.sizeGuide; else loading">
          <div class="col-md-12">
            <h5>About Us</h5>
            <hr>
          </div>
          <div class="col-md-6">
            <h3>Who We Are</h3>
            <p-editor class="full-width" [(ngModel)]="pageSettings?.about.whoWeAre" [style]="{'height':'320px'}">
              <p-header>
                    <span class="ql-formats">
                      <button class="ql-bold" aria-label="Bold"></button>
                      <button class="ql-italic" aria-label="Italic"></button>
                      <button class="ql-underline" aria-label="Underline"></button>
                    </span>
                <span class="ql-formats">
                      <button class="ql-list" value="ordered" aria-label="List Ordered"></button>
                      <button class="ql-list" value="bullet" aria-label="List"></button>
                    </span>
                <span class="ql-formats">
                      <button class="ql-link" aria-label="Link"></button>
                      <button class="ql-image" aria-label="Image"></button>
                    </span>
              </p-header>
            </p-editor>
          </div>
          <div class="col-md-6">
            <h3>Why Us?</h3>
            <p-editor class="full-width" [(ngModel)]="pageSettings?.about.whyUs" [style]="{'height':'320px'}">
              <p-header>
                    <span class="ql-formats">
                      <button class="ql-bold" aria-label="Bold"></button>
                      <button class="ql-italic" aria-label="Italic"></button>
                      <button class="ql-underline" aria-label="Underline"></button>
                    </span>
                <span class="ql-formats">
                      <button class="ql-list" value="ordered" aria-label="List Ordered"></button>
                      <button class="ql-list" value="bullet" aria-label="List"></button>
                    </span>
                <span class="ql-formats">
                      <button class="ql-link" aria-label="Link"></button>
                      <button class="ql-image" aria-label="Image"></button>
                    </span>
              </p-header>
            </p-editor>
          </div>
        </div>
        <div class="row margin-top" *ngIf="pageSettings?.sizeGuide; else loading">
          <div class="col-md-12">
            <h5>Size Guide</h5>
            <hr>
          </div>
          <div class="col-md-6">
            <h3 for="page-body">Page Body (HTML allowed)</h3>
            <p-editor class="full-width" [(ngModel)]="pageSettings.sizeGuide.body" [style]="{'height':'320px'}">
              <p-header>
                    <span class="ql-formats">
                      <button class="ql-bold" aria-label="Bold"></button>
                      <button class="ql-italic" aria-label="Italic"></button>
                      <button class="ql-underline" aria-label="Underline"></button>
                    </span>
                <span class="ql-formats">
                      <button class="ql-list" value="ordered" aria-label="List Ordered"></button>
                      <button class="ql-list" value="bullet" aria-label="List"></button>
                    </span>
                <span class="ql-formats">
                      <button class="ql-link" aria-label="Link"></button>
                      <button class="ql-image" aria-label="Image"></button>
                    </span>
              </p-header>
            </p-editor>
            <!--<textarea class="full-width" pInputTextarea id="page-body" autoResize="autoResize"-->
            <!--[(ngModel)]="pageSettings.sizeGuide.body"></textarea>-->
          </div>
          <div class="col-md-6">
            <h3 for="page-title">Page Title</h3>
            <input class="full-width" pInputText id="page-title" [(ngModel)]="pageSettings.sizeGuide.title"/>
          </div>
        </div>

        <div class="row padding-top">
          <div class="col-md-12">
            <button style="float: right;" (click)="savePageSettings()" md-raised-button color="accent">Update</button>
          </div>
        </div>
      </md-card>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="spinner">
    <md-spinner color="accent"></md-spinner>
  </div>
</ng-template>
