<div class="container margin-top">
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <div class="row">
          <div class="col-md-4">
            <button md-raised-button color="primary" (click)="locationAngular.back()">Go Back</button>
          </div>
          <div class="col-md-4 text-center">
            <h1>{{ afService?.selectedFacility?.name}}</h1>
            <h5>{{afService?.selectedLocation?.name}}</h5>
            <h3>
              {{afService?.selectedLocation?.address}}<br>
              {{afService?.selectedLocation?.address2}}<br>
              {{afService?.selectedLocation?.phone}}<br>
            </h3>
          </div>
          <div class="col-md-4">
            <img src="{{ afService?.selectedFacility?.logoUrl }}" class="logo" alt="">
          </div>
        </div>

        <md-card-header>
          <md-card-title><h2>Location Office Hours</h2></md-card-title>
        </md-card-header>

        <div class="row">
          <div class="col-md-6">
            <scs-office-hours [facilityInfo]="afService.selectedFacility" [officeHours]="officeHours" [path]="path"></scs-office-hours>
          </div>
          <div class="col-md-6">
            <scs-gate-hours [facilityInfo]="afService.selectedFacility" [gateHours]="gateHours" [path]="path"></scs-gate-hours>
          </div>
        </div>

      </md-card>
    </div>
  </div>
</div>
