<md-toolbar color="primary" class="hidden-print">
  <span class="logo" [routerLink]="[ '' ]">
    <img class="logo-img" src="https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/pug%20head.png?alt=media&token=c963daa1-4761-47ed-8186-74dce2f5ae87">
  </span>
  <marquee behavior="alternate" scrollamount="10"><h1 *ngIf="devEnv" class="dev-env">THIS IS THE DEV ENVIRONMENT</h1></marquee>

  <!-- This fills the remaining space of the current row -->
  <span class="fill-remaining-space"></span>

  <span >
    <button md-raised-button [mdMenuTriggerFor]="menu">Menu</button>
    <md-menu #menu="mdMenu">
      <button md-menu-item [routerLink]="[ 'update-password' ]">Update Password</button>
      <button md-menu-item (click)="afService.logout()">Logout</button>
    </md-menu>
  </span>
  <!--<span >-->
    <!--<a class="hvr-underline-from-center nav-button" md-button color="primary" (click)="afService.logout()">Logout</a>-->
  <!--</span>-->

  <div id="nav-icon3" class="visible-xs" (click)="toggleMobileMenu()" [class.open]="showMobileMenu">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>


  <!--<md-toolbar-row *ngIf="showMobileMenu == true" class="visible-xs">-->
    <!--<button md-button color="accent" class="hvr-shutter-out-horizontal mobile-nav-button"><span>Settings</span></button>-->
    <!--<a color="accent" class="hvr-shutter-out-horizontal mobile-nav-button" (click)="afService.logout()" (click)="toggleMobileMenu()"><span>Logout</span></a>-->
  <!--</md-toolbar-row>-->

  <!--<md-toolbar-row *ngIf="showMobileMenu == true" class="visible-xs">-->
    <!--<a class="hvr-shutter-out-horizontal mobile-nav-button" [routerLink]="[ '/portal-login' ]"><span>Portal</span></a>-->
  <!--</md-toolbar-row>-->
</md-toolbar>
