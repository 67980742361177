<div class="container margin-top">
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <div class="row">
          <div class="col-sm-4">
            <button md-raised-button color="primary" (click)="locationAngular.back()">Go Back</button>
          </div>
          <div class="col-sm-4 text-center">
            <h1>{{ afService.selectedFacility?.name}}</h1>
          </div>
          <div class="col-sm-4">
            <img src="{{ afService?.selectedFacility?.logoUrl }}" class="logo" alt="">
          </div>
        </div>
        <md-card-header>
          <md-card-title><h2>Locations</h2></md-card-title>
        </md-card-header>
        <p-dataTable [value]="afService.locations"
                     sortField="name"
                     class="text-center"
                     [responsive]="true"
                     [immutable]="false"
                     #dt>
          <p-column field="imageUrl" header="Image">
            <ng-template let-col let-car="rowData" pTemplate="body">
              <span><img class="location-image" [src]="car[col.field]" alt=""></span>
            </ng-template>
          </p-column>
          <p-column field="name" header="Name"></p-column>
          <p-column field="tagLine" header="Tag Line"></p-column>
          <p-column field="phone" header="Phone"></p-column>
          <p-column field="email" header="Email"></p-column>
          <p-column field="$key" header="">
            <ng-template let-col let-car="rowData" pTemplate="body">
              <button md-raised-button color="accent" (click)="setLocation(car)" [routerLink]="['./location/', car[col.field]]">View</button>
            </ng-template>
          </p-column>
        </p-dataTable>
      </md-card>
    </div>
  </div>
</div>
