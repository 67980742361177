<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/icons%2FOfficial-Pug-Full-Body-Circle-With-Text.png?alt=media&token=44e8314d-9881-4bca-b4d6-29d7843b9b3c" alt="">
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 col-md-offset-3">
      <div *ngIf="error" class="error" role="alert">
        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        {{error }}
      </div>
    </div>

    <div class="col-xs-12">
      <div class="wrapper">
        <form class="form-signin" (submit)="loginUser($event)">
          <h2 class="form-signin-heading text-center">Login</h2>
          <md-input-container class="full-width">
            <input mdInput [(ngModel)]="email" type="email" name="username" placeholder="Email Address" required>
          </md-input-container>
          <md-input-container class="full-width">
            <input mdInput [(ngModel)]="password" type="password" name="password" placeholder="Password" required>
          </md-input-container>
          <button md-raised-button color="accent" class="btn btn-lg btn-block full-width" type="submit">Login</button>
        </form>
      </div>
    </div>
    <div class="col-xs-12 text-center">
      <a [routerLink]="['/reset-password']">Forgot password?</a>
    </div>
  </div>
</div>
