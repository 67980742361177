<div class="row">
  <div class="col-md-12">
    <button md-button *ngIf="!data.isNew" (click)="openConfirmationDialog()" class="delete-button" color="warn" tabindex="-1">Delete</button>
    <button md-button class="close-button" (click)="onNoClick()" tabindex="-1">Close</button>
  </div>
</div>

<h1 class=" text-center" *ngIf="data.isNew">Create New Report Email</h1>
<h1 class=" text-center" *ngIf="!data.isNew">Update Report Email</h1>

<div class="row">
  <div class="col-md-6 col-md-offset-3">
    <div *ngIf="error" class="error" role="alert">
      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
      {{error }}
    </div>
  </div>
</div>


<md-accordion class="example-headers-align margin-bottom">
  <md-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle="true">
    <md-expansion-panel-header>
      <md-panel-title>
        Email
      </md-panel-title>
      <md-panel-description class="hidden-xs">
        Type the name of the email
        <md-icon>date_range</md-icon>
      </md-panel-description>
    </md-expansion-panel-header>


    <div class="row padding-top">
      <div class="col-md-6 col-md-offset-3">
        <md-form-field>
          <input mdInput placeholder="Email Name" name="emailName" [(ngModel)]="facilityEmail.name">
        </md-form-field>
      </div>
    </div>

    <div class="row margin-bottom sm-padding-top">
      <div class="col-md-4 col-md-offset-2">
        <md-select class="half" placeholder="Occurrence" name="occurrence" ngDefaultControl [(ngModel)]="facilityEmail.occurrence">
          <md-option *ngFor="let type of types" [value]="type.value">
            {{ type.name }}
          </md-option>
        </md-select>
      </div>
      <div class="col-md-4 sm-padding-top">
        <md-radio-group name="emailTimeOfDay" ngDefaultControl [(ngModel)]="facilityEmail.emailTimeOfDay">
          <md-radio-button value="morning">Morning</md-radio-button>
          <md-radio-button value="afternoon">Afternoon</md-radio-button>
          <md-radio-button value="evening">Evening</md-radio-button>
        </md-radio-group>
      </div>
    </div>


    <md-action-row>
      <button md-raised-button color="primary" (click)="nextStep()">Next</button>
    </md-action-row>
  </md-expansion-panel>


  <md-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle="true">
    <md-expansion-panel-header>
      <md-panel-title>
        Select Reports
      </md-panel-title>
      <md-panel-description class="hidden-xs">
        Select the Reports to be in the email
        <md-icon>assignment</md-icon>
      </md-panel-description>
    </md-expansion-panel-header>

    <div class="reports margin-top margin-bottom">
      <md-checkbox *ngFor="let report of reports" class="report-checkbox" [checked]="reportsAreChecked[report.value] == true" (change)="addReport(report, $event)">{{report.label}}</md-checkbox>
    </div>

    <md-action-row>
      <button md-button color="warn" (click)="prevStep()">Previous</button>
      <button md-raised-button color="primary" (click)="nextStep()">Next</button>
    </md-action-row>
  </md-expansion-panel>

  <md-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle="true">
    <md-expansion-panel-header>
      <md-panel-title>
        Recipients
      </md-panel-title>
      <md-panel-description class="hidden-xs">
        Who should receive the email?
        <md-icon>mail</md-icon>
      </md-panel-description>
    </md-expansion-panel-header>

    <div class="row padding-top">
      <div *ngIf="emailError" class="error" role="alert">
        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        {{emailError}}
      </div>
      <div class="col-sm-5">
        <md-form-field>
          <input [(ngModel)]="name" mdInput placeholder="Name">
        </md-form-field>
      </div>
      <div class="col-sm-5">
        <md-form-field>
          <input [(ngModel)]="email" mdInput placeholder="Email">
        </md-form-field>
      </div>
      <div class="col-sm-2">
        <button md-raised-button (click)="addEmail()">Add Email</button>
      </div>
    </div>

    <div class="list-wrapper" *ngIf="facilityEmail.recipientEmails.length >= 1">
      <div class="row email-top margin-top">
        <div class="col-xs-4">Name</div>
        <div class="col-xs-4">Email</div>
        <div class="col-xs-4"></div>
      </div>
      <div class="row email-row"  *ngFor="let email of facilityEmail.recipientEmails">
        <div class="col-xs-4">{{email.name}}</div>
        <div class="col-xs-4">{{email.email}}</div>
        <div class="col-xs-4"><button (click)="removeEmail(email)" class="email-button" md-button color="warn">X</button></div>
      </div>
    </div>

    <md-action-row>
      <button md-button color="warn" (click)="prevStep()">Previous</button>
      <button md-raised-button *ngIf="data.isNew" color="primary" (click)="save('save', $event)">Save Report Email</button>
      <button md-raised-button *ngIf="!data.isNew" color="primary" (click)="save('update', $event)">Update Email</button>
    </md-action-row>
  </md-expansion-panel>

</md-accordion>
<div id="spinner" *ngIf="showSpinner">
<div class="loading-spinner"></div>
</div>
