<div class="row">
  <div class="col-md-12">
    <p-dataTable [value]="(gateHours | async)"
                 selectionMode="single"
                 [(selection)]="selectedGateHour"
                 (onRowSelect)="onGateHourRowSelect($event)"
                 [responsive]="true">
      <p-header>Gate Hours</p-header>
      <p-column field="open" header="Open"></p-column>
      <p-column field="close" header="Close"></p-column>
      <p-column field="days" header="Days"></p-column>
      <p-footer>
        <div class="ui-helper-clearfix" style="width:100%">
          <button type="button" md-raised-button color="primary" style="float:left" (click)="showGateHourDialogToAdd()">
            <i class="fa fa-plus" aria-hidden="true"></i> Add
          </button>
        </div>
      </p-footer>
    </p-dataTable>
  </div>
</div>


<p-dialog header="Gate Hour Details"
          [(visible)]="displayGateHourDialog"
          [responsive]="true"
          showEffect="fade"
          [modal]="true"
          [positionTop]="100"
          [dismissableMask]="true">
  <div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="gateHour">
    <div class="ui-grid-row sm-padding-top">
      <div class="ui-grid-col-4"><label for="open2">Open</label></div>
      <div class="ui-grid-col-8"><input pInputText id="open2" [(ngModel)]="gateHour.open" /></div>
    </div>
    <div class="ui-grid-row sm-padding-top">
      <div class="ui-grid-col-4"><label for="close2">Close</label></div>
      <div class="ui-grid-col-8"><input pInputText id="close2" [(ngModel)]="gateHour.close" /></div>
    </div>
    <div class="ui-grid-row sm-padding-top">
      <div class="ui-grid-col-4"><label for="days2">Days</label></div>
      <div class="ui-grid-col-8"><input pInputText id="days2" [(ngModel)]="gateHour.days" /></div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button md-raised-button *ngIf="!newGateHour" style="float:left" (click)="deleteGateHour()"><i class="fa fa-close" aria-hidden="true"></i> Delete</button>
      <button md-raised-button color="primary" (click)="saveGateHour()"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
    </div>
  </p-footer>
</p-dialog>
