<div class="container margin-top">
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <div class="row">
          <div class="col-sm-4">
            <button md-raised-button color="primary" (click)="locationAngular.back()">Go Back</button>
          </div>
          <div class="col-sm-4 text-center">
            <h1>{{ afService?.selectedFacility?.name}}</h1>
            <h5>{{afService?.selectedLocation?.name}}</h5>
            <h3>
              {{afService?.selectedLocation?.address}}<br>
              {{afService?.selectedLocation?.address2}}<br>
              {{afService?.selectedLocation?.phone}}<br>
            </h3>
          </div>
          <div class="col-sm-4">
            <img src="{{ afService?.selectedFacility?.logoUrl }}" class="logo" alt="">
          </div>
        </div>

        <md-card-header>
          <md-card-title><h2>Available Units</h2></md-card-title>
        </md-card-header>
        <div class="ui-widget-header" style="padding:4px 10px;border-bottom: 0 none">
          <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
          <input #gbUnits type="text" pInputText placeholder="Search" style="width: 250px !important;">
        </div>
        <p-dataTable [value]="availableUnits"
                     class="text-center"
                     [responsive]="true"
                     selectionMode="single"
                     [(selection)]="selectedUnit"
                     [rows]="10"
                     [paginator]="true"
                     [immutable]="false"
                     (onRowSelect)="onLocationRowSelect($event)"
                     [globalFilter]="gbUnits"
                     #dt>
          <p-column field="unitType" header="Type"></p-column>
          <p-column field="width" header="Width"></p-column>
          <p-column field="length" header="Length"></p-column>
          <p-column field="rate" header="Rate">
            <ng-template let-col let-car="rowData" pTemplate="body">
                {{car[col.field] | currency:'USD':true:'.2-2'}}
            </ng-template>
          </p-column>
          <p-column field="pushRate" header="Push Rate">
            <ng-template let-col let-car="rowData" pTemplate="body">
              {{car[col.field] | currency:'USD':true:'.2-2'}}
            </ng-template>
          </p-column>
          <p-footer>
            <div class="ui-helper-clearfix" style="width:100%">
              <button type="button" md-raised-button color="primary" style="float:left" (click)="createNewUnit()">
                <i class="fa fa-plus" aria-hidden="true"></i> Add
              </button>
            </div>
          </p-footer>
        </p-dataTable>


        <p-dialog header="Available Units"
                  [(visible)]="displayDialog"
                  [responsive]="true"
                  [width]="500"
                  [resizable]="true"
                  showEffect="fade"
                  [modal]="true"
                  [dismissableMask]="true"
                  [positionTop]="50"
        >
          <div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="selectedUnit">
            <div class="row sm-padding-top">
              <div class="col-md-12">
                <label for="unitType">Unit Type</label>
                <input pInputText id="unitType" [(ngModel)]="selectedUnit.unitType" />
              </div>
            </div>
            <div class="row sm-padding-top">
              <div class="col-md-4">
                <label for="width">Width</label>
                <input pInputText id="width" type="number" [(ngModel)]="selectedUnit.width" />
              </div>
              <div class="col-md-4">
                <label for="length">Length</label>
                <input pInputText id="length" type="number" [(ngModel)]="selectedUnit.length" />
              </div>
              <div class="col-md-4 sm-padding-top">
                <md-checkbox [(ngModel)]="selectedUnit.isRentable">Is Rentable</md-checkbox>
              </div>
            </div>
            <div class="row sm-padding-top">
              <div class="col-md-4">
                <label for="rate">Rate</label>
                <input pInputText id="rate" type="number" [(ngModel)]="selectedUnit.rate" />
              </div>
              <div class="col-md-4">
                <label for="pushRate">Push Rate</label>
                <input id="pushRate" type="number" [(ngModel)]="selectedUnit.pushRate">
              </div>
              <div class="col-md-4 sm-padding-top">
                <md-checkbox [(ngModel)]="selectedUnit.usePushRate">Use Push Rate</md-checkbox>
              </div>
            </div>
            <div class="row sm-padding-top">
              <div class="col-md-4 sm-padding-top">
                <md-slide-toggle [(ngModel)]="selectedUnit.isInside">Inside Unit</md-slide-toggle>
              </div>
              <div class="col-md-4 sm-padding-top">
                <md-slide-toggle [(ngModel)]="selectedUnit.hasPower">Power Outlet</md-slide-toggle>
              </div>
              <div class="col-md-4 sm-padding-top">
                <md-slide-toggle [(ngModel)]="selectedUnit.hasUnitAlarms">Unit Alarms</md-slide-toggle>
              </div>
              <div class="col-md-5 sm-padding-top">
                <md-slide-toggle [(ngModel)]="selectedUnit.isClimate">Climate Controlled</md-slide-toggle>
              </div>
            </div>
            <div class="row sm-padding-top">
              <div class="col-md-4">
                <label for="numberOfUnits">Number of Units Available</label>
                <input pInputText id="numberOfUnits" type="number" [(ngModel)]="selectedUnit.numberOfUnits" />
              </div>
            </div>
          </div>
          <p-footer>
            <div class="ui-dialog-buttonpane ui-helper-clearfix">
              <button type="button" md-raised-button color="accent"  (click)="saveUnit()"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
              <button *ngIf="!isNew" type="button" md-raised-button style="float: left;" (click)="removeUnit()"><i class="fa fa-close" aria-hidden="true"></i> Delete</button>
            </div>
          </p-footer>
        </p-dialog>

      </md-card>
    </div>
  </div>
</div>
