<div class="list-wrapper margin-bottom" *ngIf="locationEmailData.length >= 1">
  <div class="row view-email-top">
    <div class="col-xs-2">Name</div>
    <div class="col-xs-4">Reports</div>
    <div class="col-xs-4">Emails</div>
    <div class="col-xs-2"></div>
  </div>
  <div class="row view-email-row"  *ngFor="let email of locationEmailData">
    <div class="col-xs-2">{{email.name}}</div>
    <div class="col-xs-4">
      <ul>
        <li *ngFor="let report of email.reports">- {{report.label}} </li>
      </ul>
    </div>
    <div class="col-xs-4">
      <ul>
        <li *ngFor="let report of email.recipientEmails"> {{report.email}} </li>
      </ul>
    </div>
    <div class="col-xs-2"><button class="email-button" (click)="openEditDialog(email)" md-button color="primary">Edit</button></div>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <button class="email-campain-button date-button" (click)="openNewDialog()">Add Email Campaign</button>
  </div>
</div>

