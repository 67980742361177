<div class="row padding-bottom">
  <div class="col-md-12">
    <p-dataTable [value]="officeHours | async"
                 selectionMode="single"
                 [(selection)]="selectedOfficeHour"
                 (onRowSelect)="onOfficeHourRowSelect($event)"
                 [responsive]="true">
      <p-header>Office Hours</p-header>
      <p-column field="open" header="Open"></p-column>
      <p-column field="close" header="Close"></p-column>
      <p-column field="days" header="Days"></p-column>
      <p-footer>
        <div class="ui-helper-clearfix" style="width:100%">
          <button type="button" md-raised-button color="primary" style="float:left" (click)="showOfficeHourDialogToAdd()">
            <i class="fa fa-plus" aria-hidden="true"></i> Add
          </button>
        </div>
      </p-footer>
    </p-dataTable>
  </div>
</div>

<p-dialog header="Office Hour Details"
          [(visible)]="displayOfficeHourDialog"
          [responsive]="true"
          showEffect="fade"
          [modal]="true"
          [positionTop]="100"
          [dismissableMask]="true">
  <div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="officeHour">
    <div class="ui-grid-row sm-padding-top">
      <div class="ui-grid-col-4"><label for="open">Open</label></div>
      <div class="ui-grid-col-8"><input pInputText id="open" [(ngModel)]="officeHour.open" /></div>
    </div>
    <div class="ui-grid-row sm-padding-top">
      <div class="ui-grid-col-4"><label for="close">Close</label></div>
      <div class="ui-grid-col-8"><input pInputText id="close" [(ngModel)]="officeHour.close" /></div>
    </div>
    <div class="ui-grid-row sm-padding-top">
      <div class="ui-grid-col-4"><label for="days">Days</label></div>
      <div class="ui-grid-col-8"><input pInputText id="days" [(ngModel)]="officeHour.days" /></div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button md-raised-button *ngIf="!newOfficeHour" style="float:left" (click)="deleteOfficeHour()"><i class="fa fa-close" aria-hidden="true"></i> Delete</button>
      <button md-raised-button color="primary" (click)="saveOfficeHour()"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
    </div>
  </p-footer>
</p-dialog>
