<div class="container margin-top margin-bottom" *ngIf="reportFormSubmitted">
  <md-card>
    <div class="row">
      <div class="col-sm-6 col-md-offset-3">
        <div *ngIf="error" class="error" role="alert">
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          {{error }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <md-form-field class="date">
          <input mdInput [mdDatepicker]="picker" [(ngModel)]="startDate" [max]="today" (click)="picker.open()" name="startDate" placeholder="Start Date">
          <md-datepicker-toggle mdSuffix [for]="picker"></md-datepicker-toggle>
          <md-datepicker #picker touchUi="true"></md-datepicker>
        </md-form-field>
      </div>
      <div class="col-sm-3">
        <md-form-field class="date">
          <input mdInput [mdDatepicker]="picker2" (click)="picker2.open()" [max]="today" [(ngModel)]="endDate" name="endDate" placeholder="End Date">
          <md-datepicker-toggle mdSuffix [for]="picker2" ></md-datepicker-toggle>
          <md-datepicker #picker2 touchUi="true"></md-datepicker>
        </md-form-field>
      </div>
      <div class="col-sm-4">
        <md-select placeholder="Select a Report" name="report" [(ngModel)]="selectedReport">
          <md-option *ngFor="let item of reports" [value]="item.value">
            {{ item.label }}
          </md-option>
        </md-select>
      </div>
      <div class="col-sm-2">
        <button class="update-button" md-raised-button color="accent" (click)="generateReport()">Update</button>
      </div>
    </div>
  </md-card>
</div>

<div class="container margin-top" *ngIf="!reportFormSubmitted">
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <div class="row">
          <div class="col-sm-4">
            <button md-raised-button color="primary" (click)="locationAngular.back()">Go Back</button>
          </div>
          <div class="col-sm-4 text-center">
            <h1>{{ afService?.selectedFacility?.name}}</h1>
            <h5>{{afService?.selectedLocation?.name}}</h5>
            <h3>
              {{afService?.selectedLocation?.address}}<br>
              {{afService?.selectedLocation?.address2}}<br>
              {{afService?.selectedLocation?.phone}}<br>
            </h3>
          </div>
          <div class="col-sm-4 hidden-xs">
            <img src="{{ afService?.selectedFacility?.logoUrl }}" class="logo" alt="">
          </div>
        </div>
        <form class="example-form">
          <div class="row">
            <div class="col-sm-6 col-md-offset-3">
              <div *ngIf="error" class="error" role="alert">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                {{error }}
              </div>
            </div>
          </div>
          <div class="row margin-top">
          <div class="col-sm-6">
            <md-form-field>
              <input mdInput [mdDatepicker]="picker" [(ngModel)]="startDate" [max]="today" (click)="picker.open()" name="startDate" placeholder="Start Date">
              <md-datepicker-toggle mdSuffix [for]="picker"></md-datepicker-toggle>
              <md-datepicker #picker touchUi="true"></md-datepicker>
            </md-form-field>
          </div>
          <div class="col-sm-6">
            <md-form-field>
              <input mdInput [mdDatepicker]="picker2" (click)="picker2.open()" [max]="today" [(ngModel)]="endDate" name="endDate" placeholder="End Date">
              <md-datepicker-toggle mdSuffix [for]="picker2" ></md-datepicker-toggle>
              <md-datepicker #picker2 touchUi="true"></md-datepicker>
            </md-form-field>
          </div>
        </div>

        <div class="row margin-top">
          <div class="col-sm-6 col-sm-offset-3">
            <md-form-field class="select-report">
              <input type="text" mdInput name="report" [formControl]="selectReportControl" [(ngModel)]="selectedReport" [mdAutocomplete]="auto">
              <md-autocomplete #auto="mdAutocomplete" placeholder="Select a Report"  >
                <md-option *ngFor="let item of filteredOptions | async"  [value]="item">
                  {{ item }}
                </md-option>
              </md-autocomplete>
            </md-form-field>
          </div>
        </div>
        </form>
        <div class="row margin-top">
          <div class="col-sm-6 col-sm-offset-3">

            <button md-raised-button color="accent" (click)="generateReport()">Generate</button>
          </div>
        </div>
      </md-card>

    </div>
  </div>
</div>
<div id="spinner" *ngIf="showSpinner">
  <div class="loading-spinner"></div>
</div>

<iframe *ngIf="showIframe" #iframe [src]="url" frameborder="0"></iframe>
<!--<app-report *ngIf="showReport" [data]="data" [selectedReport]="selectedReport"></app-report>-->
