<div class="container padding-top">
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <div class="row">
          <div class="col-md-12">

            <md-card-header>
              <md-card-title><h2>Facilities</h2></md-card-title>
            </md-card-header>
            <div class="ui-widget-header" style="padding:4px 10px;border-bottom: 0 none">
              <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
              <input #gb type="text" pInputText placeholder="Search" style="width: 250px !important;">
            </div>
            <p-dataTable [value]="afService.facilities"
                         sortField="name"
                         class="text-center"
                         [rows]="10"
                         [responsive]="true"
                         [paginator]="true"
                         [immutable]="false"
                         [globalFilter]="gb" #dt>
              <p-column field="logoUrl" header="Logo">
                <ng-template let-col let-car="rowData" pTemplate="body">
                  <span><img class="facility-logo" [src]="car[col.field]" alt=""></span>
                </ng-template>
              </p-column>
              <p-column field="name" header="Name" [sortable]="true"></p-column>
              <p-column field="tagLine" header="Tag Line"></p-column>
              <p-column field="$key" header="">
                <ng-template let-col let-car="rowData" pTemplate="body">
                  <button md-raised-button color="accent" [routerLink]="['facility/', car[col.field]]">View</button>
                </ng-template>
              </p-column>
            </p-dataTable>

          </div>
        </div>
      </md-card>
    </div>
  </div>
</div>
