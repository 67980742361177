<div class="container margin-top">
  <div class="row">
    <div class="col-xs-12">
      <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/icons%2FOfficial-Pug-Full-Body-Circle-With-Text.png?alt=media&token=44e8314d-9881-4bca-b4d6-29d7843b9b3c" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <md-card-header>
          <md-card-title><h2>Reset your Password</h2></md-card-title>
        </md-card-header>

        <form [formGroup]="form" (submit)="resetPw($event)">
          <div class="row min-error">
            <div *ngIf="error" class="error col-md-6 col-md-offset-3">
              {{error}}
            </div>
          </div>
          <div *ngIf="success">
            <div class="row min-error">
              <div class="success col-md-6 col-md-offset-3">
                {{success}}
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <label for="email">Your Email</label>
              <input id="email" formControlName="email" [formGroup]="form" class="full-width" name="email" type="email" pInputText />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <button md-raised-button class="margin-top" type="submit" color="primary">Reset</button>
            </div>
          </div>
        </form>
      </md-card>
    </div>
  </div>
</div>
