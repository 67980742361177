<div class="container margin-top">
  <div class="row">
    <div class="col-md-12">
      <md-card>
        <md-card-header>
          <md-card-title><h2>Change Your Password</h2></md-card-title>
        </md-card-header>

        <form [formGroup]="form" (submit)="updatePw($event)">
            <div class="row min-error">
              <div class="error col-md-6 col-md-offset-3" *ngIf="form.controls.confirmPassword.errors?.MatchPassword">Passwords do not match</div>
              <div *ngIf="error" class="error col-md-6 col-md-offset-3">
                {{error}}
              </div>
            </div>
          <div *ngIf="success">
            <div class="row min-error">
              <div class="success col-md-6 col-md-offset-3">
                {{success}}
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-4 col-md-offset-2">
              <label for="password">Password</label>
              <input id="password" formControlName="password" [formGroup]="form" class="full-width" name="password" type="password" pPassword />
            </div>
            <div class="col-md-4">
              <label for="password2">Repeat Password</label>
              <input id="password2" formControlName="confirmPassword" [formGroup]="form" class="full-width" name="password2" type="password" pPassword />
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 col-md-offset-2">
              <button md-raised-button class="margin-top" type="submit" color="primary">Submit</button>
            </div>
          </div>
        </form>
      </md-card>
    </div>
  </div>
</div>
